<template>
  <div class="photographers">
    <main class="layout__main pt-0">
      <intro class="mb-negative-xxl" @sendRequest="showModal"></intro>
      <services class="mb-xxl"></services>
      <cases></cases>
      <features v-if="false" @sendRequest="showModal"></features>
      <how></how>
      <actions @sendRequest="showModal"></actions>
    </main>

    <request-modal ref="requestModal"></request-modal>
  </div>
</template>


<script lang="js">
import { mapGetters } from "vuex";
import intro from './sections/intro/intro.vue';
import services from './sections/services/services.vue';
import cases from './sections/cases/cases.vue';
import features from './sections/features/features.vue';
import how from './sections/how/how.vue';
import actions from './sections/actions/actions.vue';
import requestModal from './components/request-modal.vue';

export default {
  name: 'Photographers',
  components: {
    requestModal,
    intro,
    services,
    cases,
    features,
    how,
    actions,
  },
  data() {
    return {
      modal: null,
    }
  },
  computed: {
    ...mapGetters('SettingsState', ['lang']),
  },
  watch: {
    lang() {
      window.location.reload();
    },
  },
  created() {
    this.$root.$modals = {};
    this.$root.openBitrixForm = () => {
      const btn = document.querySelector('.bitrix-form');
      if (btn) {
        btn.click();
      }
    };
    if (!this.$root.useAnimations) {
      this.$root.useAnimations = window.innerWidth > 1199;
    }
  },
  mounted() {
    this.$store.state.showMenu = true;
    this.$store.state.buttonCart = true;
    this.modal = this.$refs.requestModal;
  },
  methods: {
    showModal() {
      const modal = this.modal && this.modal.$refs.modal;
      if (!modal) return;
      modal.open();
    }
  }
};
</script>


<style lang="scss" scoped>
  @import './photographers.scss';
</style>
