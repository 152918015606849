<template>
  <modal
    name="request-modal"
    align="center"
    ref="modal"
  >
    <form
      class="form"
      :class="showErrors ? 'form_show-err' : ''"
      novalidate="novalidate"
      @submit.prevent="submit"
    >
      <div v-if="!dataSent" class="modal__heading modal__item">
        <h3 class="modal__title" v-html="$t('business/requestModal/title')"></h3>
        <div class="modal__subtitle" v-html="$t('business/requestModal/subtitle')"></div>
      </div>
      <div v-if="dataSent && sentState === 204" class="modal__heading modal__item">
        <h3 class="modal__title" v-html="$t('photographers/requestModal/sentTitle')"></h3>
        <div class="modal__subtitle" v-html="$t('business/requestModal/sentSubtitle')"></div>
      </div>
      <div v-if="dataSent && sentState !== 204" class="modal__heading modal__item">
        <h3 class="modal__title" v-html="$t('photographers/requestModal/errorTitle')"></h3>
        <div class="modal__subtitle modal__subtitle_error" v-html="$t('photographers/requestModal/errorSubtitle')"></div>
      </div>
      <div v-if="!dataSent" class="modal__body modal__item">
        <div class="form__item" :class="$v.fields.name.$error ? 'has-error' : ''">
          <div class="field">
            <div class="field__label">
              <label class="label" v-html="$t('photographers/requestModal/nameLabel')"></label>
            </div>
            <div class="field__input">
              <div class="input">
                <input
                  v-model.trim="fields.name"
                  class="input__el"
                  type="text"
                  name="name"
                  :placeholder="$t('photographers/requestModal/namePlaceholder')"
                >
                <div class="input__border"></div>
                <div class="input__icons">
                  <div class="input__icon input__icon-err">
                    <svg aria-hidden="true" class="svg-icon">
                      <use xlink:href="#icon-exclamation-circle-s"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="field__msg">
              <div class="field-msg">
                <div class="color-danger" v-html="$t('photographers/requestModal/nameMsg')"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="form__item" :class="$v.fields.phone.$error ? 'has-error' : ''">
          <div class="field">
            <div class="field__label">
              <label class="label" v-html="$t('business/requestModal/phoneLabel')"></label>
            </div>
            <div class="field__input">
              <div class="input">
                <input
                  v-model.trim="fields.phone"
                  class="input__el"
                  type="tel"
                  name="phone"
                  placeholder="+7 (XXX) XXX-XX-XX"
                  v-mask="'+7 (###) ###-##-##'"
                  ref="phone"
                >
                <div class="input__border"></div>
                <div class="input__icons">
                  <div class="input__icon input__icon-err">
                    <svg aria-hidden="true" class="svg-icon">
                      <use xlink:href="#icon-exclamation-circle-s"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="field__msg">
              <div class="field-msg">
                <div class="color-danger" v-html="$t('business/requestModal/phoneMsg')"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="form__item" :class="$v.fields.comment.$error ? 'has-error' : ''">
          <div class="field">
            <div class="field__label">
              <label class="label" v-html="$t('photographers/requestModal/commentLabel')"></label>
            </div>
            <div class="field__input">
              <div class="input">
                <input
                  v-model.trim="fields.comment"
                  class="input__el"
                  type="text"
                  name="comment"
                  :placeholder="$t('business/requestModal/commentPlaceholder')"
                >
                <div class="input__border"></div>
                <div class="input__icons">
                  <div class="input__icon input__icon-err">
                    <svg aria-hidden="true" class="svg-icon">
                      <use xlink:href="#icon-exclamation-circle-s"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="field__msg">
              <div class="field-msg">
                <div class="color-danger" v-html="$t('business/requestModal/commentPlaceholder')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!dataSent" class="modal__item">
        <button class="button button_primary button_lg" type="submit">
          <span class="button__text" v-html="$t('common/send-request')"></span>
        </button>
        <div>
          <br>
          <div class="text-xs color-muted">
            <span v-html="$t('photographers/requestModal/privacy-1')"></span> <a href="/dogovor-oferta.pdf" target="_blank" class="link" v-html="$t('photographers/requestModal/privacy-2')"></a>
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script lang="js">
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import Modal from "./modal";
import boringApi from '@/services/api/boring-api';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'RequestModal',
  mixins: [validationMixin],
  directives: { mask },
  components: {
    Modal,
  },
  props: {
    showKey: String,
  },
  data() {
    return {
      modal: null,
      showErrors: false,
      sending: false,
      dataSent: false,
      sentState: 0,
      fields: {
        name: null,
        phone: null,
        comment: null,
      },
    };
  },
  validations: {
    fields: {
      name: {
        required,
      },
      phone: {
        required,
        masked(value) {
          const placeholder = this.$refs.phone && this.$refs.phone.placeholder;
          if (!value || !placeholder) {
            return true;
          }
          return value.length === placeholder.length;
        },
      },
      comment: {
        required,
      },
    },
  },
  computed: {
    hasErrors() {
      return !!(this.showErrors && this.$v.$anyError);
    },
  },
  methods: {
    getData() {
      const { fields } = this;
      return {
        notificationId: uuidv4(),
        notificationType: 'business_questionnaire_completed',
        tenantId: 'fed7b705-acfd-4576-88a3-e3ebb74268b2',
        parameters: {
          name: fields.name,
          phoneNumber: this.cleanPhone(fields.phone),
          comment: fields.comment,
        },
      };
    },
    saveData() {
      boringApi.saveAnswers(this.getData())
        .then((response) => {
          if (response.status === 204) {
            this.dataSent = true;
            this.sentState = response.status;
            setTimeout(() => {
              this.sending = false;
              this.showErrors = false;
              if (this.modal) this.modal.close();
              this.resetFields();
            }, 6000);
          }
        })
        .catch((error) => {
          const status = error.response && error.response.status;
          this.dataSent = true;
          this.sentState = status;
          setTimeout(() => {
            this.sending = false;
            this.showErrors = false;
            if (this.modal) this.modal.close();
          }, 6000);
        });
    },
    cleanPhone(phone) {
      return phone ? `+${phone.replace(/\D/g, '')}` : null;
    },
    resetFields() {
      const keys = Object.keys(this.fields);
      for (let i = 0; i < keys.length; i += 1) {
        this.fields[keys[i]] = null;
      }
    },
    submit() {
      if (this.sending) {
        return;
      }
      this.showErrors = true;
      this.$v.$touch();
      if (this.hasErrors) {
        return;
      }
      this.sending = true;
      this.saveData();
    },
  },
  watch: {
    showKey() {
      this.showErrors = false;
      this.sending = false;
      this.dataSent = false;
      this.sentState = 0;
    },
  },
  mounted() {
    this.modal = this.$refs.modal;
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.field__msg {
  text-align: left;
}
.link:link,
.link:hover,
.link:active,
.link:visited {
  font-weight: bold;
  color: inherit;
  text-decoration: none;
}
</style>
