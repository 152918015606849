<template>
  <section class="org-text section section_lg">
    <div class="container">
      <div class="section__group m-0">
        <div class="grid grid_v-margins-lg">
          <div class="grid__col grid__col_lg-14">
            <div class="section__header">
              <h2 class="h1" v-html="$t('organizers/features/header')"></h2>
            </div>
            <div class="text-lg color-black-85" v-html="$t('organizers/features/subheader')"></div>
          </div>
          <div class="grid__col grid__col_lg-1 d-none d-md-block"></div>
          <div class="grid__col grid__col_lg-9">
            <div class="block block_wide">
              <div class="block__intro">
                <div class="block__intro-item">
                  <button
                    v-if="$root.$i18n.locale === 'ru'"
                    class="button button_lg button_primary button_wide"
                    @click.prevent="$root.openBitrixForm()"
                  >
                    <span class="button__text" v-html="$t('organizers/intro/requestCalculation')"></span>
                  </button>
                  <a
                    v-else
                    class="button button_lg button_primary button_wide"
                    href="mailto:info@mysport.photo"
                  >
                    <span class="button__text" v-html="$t('organizers/intro/requestCalculation')"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section__group">
        <div class="divider"></div>
      </div>
      <div class="section__group">
        <div class="grid grid_v-margins">
          <div
            class="grid__col grid__col_xl-8"
            v-for="(item, i) in features"
            :key="i"
          >
            <div ref="blocks" class="org-feature block block_md block_wide">
              <div class="block__intro">
                <div class="block__intro-item block__intro-item_sm">
                  <h3 v-html="item.title"></h3>
                </div>
                <div
                  class="block__intro-item block__intro-item_sm color-black-85 text-content"
                >
                  <p
                    v-for="(p, i) in item.text"
                    :key="i"
                    v-html="p"
                  >
                  </p>
                </div>
                <div class="block__intro-item" v-if="item.learnMore">
                  <a class="link link_primary text-sm" :href="item.learnMore" v-html="$t('photographers/features/learnMore')"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import ScrollMagic from 'scrollmagic';
import { TimelineLite, TweenLite } from 'gsap';

export default {
  name: 'Features',
  data() {
    return {
      features: [
        {
          title: this.$t('organizers/features/title-1').toString(),
          text: this.$t('organizers/features/text-1'),
        },
        {
          title: this.$t('organizers/features/title-2').toString(),
          text: this.$t('organizers/features/text-2'),
        },
        {
          title: this.$t('organizers/features/title-3').toString(),
          text: this.$t('organizers/features/text-3'),
        },
      ],
    };
  },
  methods: {
    initAnimation() {
      const { blocks } = this.$refs;
      const controller = new ScrollMagic.Controller();
      const ease = window.Power2.easeOut;

      blocks.forEach((block) => {
        const tl = new TimelineLite();

        TweenLite.set(block, {
          y: 40,
        });

        tl
          .to(block, 0.9, {
            y: 0,
            ease,
          }, 0.1);

        new ScrollMagic.Scene({
          triggerElement: block,
          triggerHook: 1,
          reverse: false,
        })
          .setTween(tl)
          .addTo(controller);
      });
    },
  },
  mounted() {
    if (this.$root.useAnimations) {
      this.initAnimation();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.org-text {

  &__last {

    @media (min-width: breakpoint(lg)) {
      order: 1;
    }
  }
}

.org-feature {
  height: 100%;
}
</style>
