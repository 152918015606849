




































































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import BaseComponent from "@/components/base-component.vue";
import Alert from "@/components/alert/alert.vue";
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconPhone from "@/components/icons/icon-phone.vue";
import IconEmail from "@/components/icons/icon-email.vue";
import IconSafetyFloat from "@/components/icons/icon-safety-float.vue";
import IconVkontakte from "@/components/icons/socials/icon-vkontakte.vue";
import IconInstagram from "@/components/icons/socials/icon-instagram.vue";
import { CartState } from "@/store/modules/cart";
import { SettingsState } from "@/store/modules/settings";

@Component({
  name: "Contacts",
  components: {
    Alert,
    BaseIcon,
    IconPhone,
    IconEmail,
    IconSafetyFloat,
    IconVkontakte,
    IconInstagram,
  }
})
export default class Contacts extends BaseComponent {

  private readonly cartState: CartState = getModule(CartState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  alertState: string = 'success';

  mapLink: string = 'https://goo.gl/maps/PuGEiPrv6nCLRqW76';
  
  participants: any[] = [
    {
      type: 'email',
      text: 'support@mysport.photo',
      link: 'mailto:support@mysport.photo?subject="[Support]"',
      linkText: 'contacts/sendEmail',
    },
    {
      type: 'note',
      text: 'contacts/participantsNote',
    },
  ];

  business: any[] = [
    {
      type: 'email',
      text: 'info@mysport.photo',
      link: 'mailto:info@mysport.photo?subject="[Business]"',
      linkText: 'contacts/sendEmail',
    },
  ];

  organizersEn: any[] = [
    {
      type: 'email',
      text: 'contacts/partner-text',
      link: 'mailto:info@mysport.photo',
      linkText: 'info@mysport.photo',
    },
    {
      type: 'email-support',
      text: 'contacts/support-text',
      link: 'mailto:support@mysport.photo',
      linkText: 'support@mysport.photo',
    },
  ];

  photographers: any[] = [
    {
      type: 'email',
      text: 'info@mysport.photo',
      link: 'mailto:info@mysport.photo?subject="[Photographers]"',
      linkText: 'contacts/sendEmail',
    },
  ];

  socials: any[] = [
    {
      type: 'vkontakte',
      text: 'VKontakte',
      link: 'https://vk.com/mysport.photo',
      linkText: 'contacts/goTo',
      target: '_blank',
    },
  ];

  participantsMEP: any[] = [
    {
      type: 'email',
      text: 'support@myevent.photo',
      link: 'mailto:support@myevent.photo?subject="[Support]"',
      linkText: 'contacts/sendEmail',
    },
    {
      type: 'note',
      text: 'contacts/participantsNote',
    },
  ];

  businessMEP: any[] = [
    {
      type: 'email',
      text: 'info@myevent.photo',
      link: 'mailto:info@myevent.photo?subject="[Business]"',
      linkText: 'contacts/sendEmail',
    },
  ];

  photographersMEP: any[] = [
    {
      type: 'email',
      text: 'info@myevent.photo',
      link: 'mailto:info@myevent.photo?subject="[Photographers]"',
      linkText: 'contacts/sendEmail',
    },
  ];

  groupsRu: any[] = [
    {
      title: 'appBar/menuCompetitors',
      items: this.participants,
    },
    {
      title: 'appBar/menuBusiness',
      items: this.business,
    },
    {
      title: 'appBar/menuPhotographers',
      items: this.photographers,
    },
    {
      title: 'contacts/titleSocials',
      items: this.socials,
    },
  ];

  groupsRuMEP: any[] = [
    {
      title: 'appBar/menuCompetitors',
      items: this.participantsMEP,
    },
    {
      title: 'appBar/menuBusiness',
      items: this.businessMEP,
    },
    {
      title: 'appBar/menuPhotographers',
      items: this.photographersMEP,
    },
  ];

  groupsEn: any[] = [
    {
      title: 'contacts/group-title',
      items: this.organizersEn,
    },
  ];

  get theme(): any {
    return this.settingsState.theme;
  }

  get themeDone(): boolean {
    return this.settingsState.themeDone;
  }

  get lang(): string {
    return this.settingsState.lang;
  }

  get groups(): any[] {
    if (this.lang === 'ru') return this.theme === 'MyEventPhoto' ? this.groupsRuMEP : this.groupsRu;
    else return this.groupsEn;
  }

  getImageSrc(): string {
    if (this.theme === 'MyEventPhoto') {
      return '/img/contacts-bg-myevent.jpg';
    }
    if (this.lang !== 'ru') {
      return '/img/contacts-bg-en.jpg';
    }
    return '/img/contacts-bg.jpg';
  }

  getTitle(): string {
    return this.theme === 'MyEventPhoto' && this.lang !== 'ru' ? this.$t('contacts/titleMyEvent').toString() : this.$t('contacts/title').toString();
  }

  getSubtitle(): string {
    return this.theme === 'MyEventPhoto' && this.lang !== 'ru' ? this.$t('contacts/subtitleMyEvent').toString() : this.$t('contacts/subtitle').toString();
  }

  async updateAlertState(): Promise<void> {
    let state: string = '';
    const d: Date = new Date(Date.now());
    if (d.getUTCDay() < 1 || d.getUTCDay() > 5) state = 'danger';
    else if (d.getUTCHours() < 5 || d.getUTCHours() >= 15) state = 'danger';
    else state = 'success';

    this.alertState = state;
  }

  async mounted(): Promise<void> {    
    this.$store.state.buttonHome = true;
    this.$store.state.buttonBack = false;
    this.$store.state.buttonCart = true;
    this.$store.state.showMenu = true;

    window.scrollTo(0, 0);

    await this.cartState.loadCartId();

    if (this.cartState.cartId != "") { 
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      
      if (this.cartState.isCartError) {
        await this.cartState.removeCart();
      }
    }

    this.$store.state.goBack = "";
    this.$store.state.goPage = "";

    await this.updateAlertState();

    this.settingsState.trackPage("/contacts");
  }

}
