<template>
  <section class="org-text section section_lg">
    <div class="container">
      <div class="section__group m-0">
        <div class="grid grid_v-margins-lg">
          <div class="grid__col">
            <div class="section__header">
              <h2 class="h1" v-html="$t('business/statistic/header')"></h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container org-text__container">
      <div class="section__group mt-0">
        <div class="grid grid_v-margins-lg">
          <div class="grid__col org-text__col">
            <div
              class="org-text__bg"
              :style="'background-image: url(/img/business/statistic.jpg)'"
            >
              <div class="org-text__stat">
                <div class="text-sm" v-html="$t('business/statistic/visitors')"></div>
                <div class="org-text__stat-value text-lg" v-html="$t('business/statistic/visitorsCount')"></div>
              </div>
              <div class="org-text__stat">
                <div class="text-sm" v-html="$t('business/statistic/accounts')"></div>
                <div class="org-text__stat-value text-lg" v-html="$t('business/statistic/accountsCount')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section__group mt-0">
        <div class="grid">
          <div
            class="grid__col org-text__block"
            :class="!isTablet ? 'grid__col_md-12' : ''"
          >
            <div class="org-text__block-text" v-html="$t('business/statistic/sexAndAge')"></div>
            <div class="org-text__block-image">
              <img :src="'/img/business/' + agesImg" />
            </div>
          </div>
          <div
            class="grid__col org-text__block"
            :class="!isTablet ? 'grid__col_md-12' : ''"
          >
            <div class="org-text__block-text" v-html="$t('business/statistic/geography')"></div>
            <div class="org-text__block-image">
              <img :src="'/img/business/' + regionsImg" />
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="grid__col">
            <div class="org-text__block-text"></div>
            <div class="org-text__block-image">
              <img :src="'/img/business/' + manWomanImg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

export default {
  name: 'Statistic',
  computed: {
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isTablet() {
      return window.innerWidth >= 768 && window.innerWidth <= 1024;
    },
    agesImg() {
      if (this.isTablet || this.isDesktop) {
        return this.$root.$i18n.locale === 'ru' ? 'ages-lg.svg' : 'ages-lg-en.svg';
      }
      return this.$root.$i18n.locale === 'ru' ? 'ages.svg' : 'ages-en.svg';
    },
    regionsImg() {
      if (this.isTablet || this.isDesktop) {
        return this.$root.$i18n.locale === 'ru' ? 'regions-lg.svg' : 'regions-lg-en.svg';
      }
      return this.$root.$i18n.locale === 'ru' ? 'regions.svg' : 'regions-en.svg';
    },
    manWomanImg() {
      if (this.isTablet || this.isDesktop) {
        return this.$root.$i18n.locale === 'ru' ? 'man-woman-lg.svg' : 'man-woman-lg-en.svg';
      }
      return this.$root.$i18n.locale === 'ru' ? 'man-woman.svg' : 'man-woman-en.svg';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.org-text {

  @media (min-width: breakpoint(lg)) {
    background-color: #fff;
    padding-top: 96px;

    .h1 {
      text-align: center;
    }
  }

  &__container {
    background: #fff;
    padding: 48px 20px;
  }

  &__col {
    position: relative;
    height: 280px;

    @media (min-width: breakpoint(md)) {
      height: 224px;
    }
  }

  &__bg {
    background-size: cover;
    background-position: bottom center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__stat {
    width: 249px;
    height: 72px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 12px;
    text-align: center;
  }

  &__stat:first-child {
    margin-bottom: 24px;

    @media (min-width: breakpoint(md)) {
      margin-bottom: 0;
      margin-right: 24px;
    }
    @media (min-width: breakpoint(lg)) {
      margin-right: 72px;
    }
  }

  &__stat-value {
    font-weight: 600;
  }

  &__block {
    margin-top: 48px;
  }

  &__block-text {
    font-weight: bold;
    font-size: 24px;
    line-height: 30/24*1em;
    text-align: center;
    margin-bottom: 48px;
  }

  &__block-image {
    text-align: center;
  }
}
</style>
