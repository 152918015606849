<template>
  <section class="org-services">
    <div class="container org-services__container">
      <div class="section__group m-0">
        <div class="grid grid_v-margins">
          <div
            class="grid__col org-services__col"
            v-for="(item, i) in services"
            :key="i"
          >
            <div class="block__intro">
              <div class="org-services__text">
                <div class="block__intro-item">
                  <h2 class="h2" v-html="item.title"></h2>
                </div>
                <div
                  class="block__intro-item text-lg color-black-85"
                  v-html="item.text"
                ></div>
              </div>
              <div class="block__intro-item org-services__image" v-if="item.img">
                <img
                  :src="'/img/' + item.img"
                  class="block__intro-image"
                  alt=""
                >
              </div>
            </div>
          </div>
          <div class="block__intro">
            <div class="block__intro-item">
              <img
                :src="`/img/business/${lang === 'ru' ? 'special-offers.svg' : 'special-offers-en.svg'}`"
                width="100%"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

export default {
  name: 'PartnerServices',
  data() {
    return {
      services: [
        {
          title: this.$t('business/partnerServices/title-1').toString(),
          text: this.$t('business/partnerServices/text-1').toString(),
          img: this.$root.$i18n.locale === 'ru' ? 'business/partner-services-1.jpg' : 'business/partner-services-1-en.jpg',
        },
        {
          title: this.$t('business/partnerServices/title-2').toString(),
          text: this.$t('business/partnerServices/text-2').toString(),
          img: this.$root.$i18n.locale === 'ru' ? 'business/partner-services-2.jpg' : 'business/partner-services-2-en.jpg',
        },
        {
          title: this.$t('business/partnerServices/title-3').toString(),
          text: this.$t('business/partnerServices/text-3').toString(),
          img: 'business/partner-services-3.jpg',
        },
      ],
      lang: '',
    };
  },
  mounted() {
    this.lang = localStorage.getItem('appLanguage') || 'ru';
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.org-services {
  position: relative;
  margin-top: 48px;
  background: #fff;

  &__container {
    padding: 32px 20px;
  }

  &__text {
    @media (min-width: breakpoint(lg)) {
      width: 50%;
      margin: auto 48px auto 0;
      order: 1;
    }
  }

  &__image {
    border: 2px solid $color-black-15;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    text-align: center !important;
    @media (min-width: breakpoint(lg)) {
      width: 50%;
      order: 2;
    }
  }

  &__col:nth-child(even) {
    @media (min-width: breakpoint(lg)) {
      .org-services__text {
        order: 2;
        margin-right: 0;
      }
      .org-services__image {
        order: 1;
        margin-right: 48px;
      }
    }
  }
}

.grid {
  justify-content: center;
}

.block {
  height: 100%;
  padding: 32px 24px;

  &__intro {
    @media (min-width: breakpoint(lg)) {
      display: flex;
    }
  }

  &__intro-item {
    margin-bottom: 20px;
    text-align: left;
  }

  &__intro-image {
    height: 192px;
    @media (min-width: breakpoint(md)) {
      height: 360px;
    }
  }

  @media (min-width: breakpoint(md)) {
    padding: 32px 40px;
  }

}
</style>
