<template>
  <section id="how" class="section section_lg org-how">
    <div class="container">
      <div class="section__group m-0">
        <h2 class="h1" v-html="$t('organizers/intro/howItWorks')"></h2>
      </div>
      <div class="section__group">
        <div class="org-steps">
          <div
            class="org-steps__item"
            v-for="(item, i) in steps"
            :key="i"
          >
            <div class="org-step">
              <div class="org-step__num"><span class="h2 m-0">{{ i + 1 }}</span></div>
              <div
                class="org-step__title"
                v-html="item.title"
              ></div>
            </div>
            <div
              ref="steps"
              class="org-steps__item-arrow"
              v-if="i < steps.length - 1"
            >
              <Icon name="arrow-right"></Icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import ScrollMagic from 'scrollmagic';
import { TweenLite, TimelineLite } from 'gsap';

export default {
  name: 'How',
  data() {
    return {
      steps: [
        {
          title: this.$t('organizers/how/title-1').toString(),
        },
        {
          title: this.$t('organizers/how/title-2').toString(),
        },
        {
          title: this.$t('organizers/how/title-3').toString(),
        },
        {
          title: this.$t('organizers/how/title-4').toString(),
        },
        {
          title: this.$t('organizers/how/title-5').toString(),
        },
      ],
    };
  },
  methods: {
    initAnimation() {
      const { steps } = this.$refs;
      const controller = new ScrollMagic.Controller();
      const tl = new TimelineLite();
      const ease = window.Power2.easeOut;

      TweenLite.set(steps, {
        autoAlpha: 0,
        x: -24,
      });

      tl
        .staggerTo(steps, 0.75, {
          autoAlpha: 1,
          x: 0,
          ease,
        }, 0.15);

      new ScrollMagic.Scene({
        triggerElement: this.$el,
        triggerHook: 0.9,
        reverse: false,
      })
        .setTween(tl)
        .addTo(controller);
    },
  },
  mounted() {
    if (this.$root.useAnimations) {
      this.initAnimation();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.org-how {
  background: linear-gradient(90deg, #FF8052 0%, #FF3300 100%);
  color: $color-white;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  @media (min-width: breakpoint(md)) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.org-steps {
  display: inline-block;
  margin: 0 -20px;
  text-align: center;
  max-width: 476px;
  margin: 16px 0;

  @media (min-width: breakpoint(lg)) {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }

  &__item {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    position: relative;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: breakpoint(lg)) {
      margin-bottom: 0;
    }
  }

  &__item-arrow {
    width: 24px;
    height: 64px;
    margin: 0 auto;
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    transform: translateX(-50%);

    @media (min-width: breakpoint(lg)) {
      display: block;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.org-step {
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: left;

  @media (min-width: breakpoint(lg)) {
    display: block;
    text-align: center;
  }

  &__num {
    display: inline-block;
    vertical-align: top;
    border: 2px solid;
    border-radius: 50%;
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    line-height: 44px;
    margin-right: 24px;
    text-align: center;

    @media (min-width: breakpoint(md)) {
      width: 64px;
      height: 64px;
      line-height: 60px;
    }

    @media (min-width: breakpoint(lg)) {
      margin: 0 0 20px 0;
    }

    > span {
      line-height: inherit;
    }
  }

  &__title {
    @include text-lg;
  }
}
</style>
