<template>
  <div 
    class="alert"
    :class="getClasses()"
  >
    <div class="alert__container">
      <div class="container__icon" :class="$root.isMobile ? '' : 'container__icon--large'">
        <base-icon width="16" height="16" viewBox="0 0 24 24">
          <icon-clock :fill="state === 'success' ? 'var(--color-success)' : 'var(--color-danger)'" />
        </base-icon>
      </div>
      <div class="container__text">
        <div class="text__message">
          {{ message }}
        </div>
      </div>
      <div class="container__status">
        {{ state === 'success' ? 'Online' : 'Offline' }}
      </div>
    </div>

  </div>
</template>


<script lang="js">
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconClock from '@/components/icons/icon-clock.vue';

export default {
  name: 'Alert',
  props: {
    options: Object,
  },
  components: {
    BaseIcon,
    IconClock,
  },
  computed: {
    message() {
      return this.$props.options.message;
    },
    state() {
      return this.$props.options.state;
    },
  },
  methods: {
    getClasses() {
      const classes = [];
      if (this.state === 'success') {
        classes.push('alert--success');
      }
      if (this.state === 'danger') {
        classes.push('alert--danger');
      }
      return classes;
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./alert";
</style>