<template>
  <div
    class="modal"
    :class="[align ? 'text-' + align : '', isActive ? 'active' : '']"
  >
    <div class="modal__overlay overlay" @click="close"></div>
    <div class="modal__window">
      <button class="link modal__close" type="button" @click="close">
        <Icon name="close" size="l"></Icon>
      </button>
      <div class="modal__container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'Modal',
  data() {
    return {
      isActive: false,
    };
  },
  props: {
    name: {
      type: String,
    },
    title: String,
    subtitle: String,
    align: String,
    _isActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    open() {
      this.$el.scrollTop = 0;
      this.$emit('beforeOpen');
      this.isActive = true;
      document.body.style.overflowY = 'hidden';
      document.body.classList.add('has-modal');
    },
    close() {
      const vm = this;
      this.isActive = false;
      document.body.style.overflowY = '';
      document.body.classList.remove('has-modal');

      setTimeout(() => {
        vm.$emit('afterClose');
      }, 200);
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (oldRoute.name && newRoute.name !== oldRoute.name && this.isActive) {
        this.close();
      }
    },
  },
  created() {
    // eslint-disable-next-line no-underscore-dangle
    if (this._isActive) {
      this.open();
    }
    if (this.name) {
      this.$root.$modals[this.name] = this;
    }
  },
  beforeDestroy() {
    if (this.isActive) {
      this.close();
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "~@rr-component-library/common/src/scss/mixins.scss";
  @import "@/scss/colors.scss";
  @import "@/scss/static-pages/main.scss";
  @import "@/scss/static-pages/components/modal.scss";
</style>
  