/* eslint-disable */
import { TweenMax } from 'gsap/TweenMax';
import 'animation.gsap';
import { ScrollToPlugin } from 'ScrollToPlugin';
import ScrollMagic from 'scrollmagic';

const plugins = [ScrollToPlugin];
const controller = new ScrollMagic.Controller();

controller.scrollTo((newpos, props) => {
  let { offset } = props;
  let { duration } = props;

  if (isNaN(offset)) {
    const header = document.querySelector('.header');
    offset = header ? header.offsetHeight : 0;
  }
  if (isNaN(duration)) {
    duration = 0.4;
  }

  TweenMax.to(window, duration, {
    scrollTo: {
      y: newpos - offset,
      autoKill: false,
    },
  });
});

function scrollToId(id, topOffset, duration) {
  const el = document.getElementById(id);
  if (!el || el.style.display === 'none') {
    return;
  }
  const header = document.querySelector('.header .container') || document.querySelector('.header');
  const headerOffset = header ? header.offsetHeight : 0;
  const offset = (topOffset || topOffset === 0) ? topOffset : headerOffset;
  controller.scrollTo(`#${id}`, { offset, duration });
}

export {
  scrollToId,
};
