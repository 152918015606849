<template>
  <section
    class="business-nav mt-0"
  >
    <div class="business-nav__content">
      <div
        class="business-nav__item text-md"
        :class="activeNav === 1 ? 'business-nav__item_active' : ''"
        @click="changeNav(1)"
      >
        <div v-html="$t('business/businessNav/partners')"></div>
        <div v-if="activeNav === 1" class="business-nav__active-underline"></div>
      </div>
      <div
        class="business-nav__item text-md"
        :class="activeNav === 2 ? 'business-nav__item_active' : ''"
        @click="changeNav(2)"
      >
        <div v-html="$t('business/businessNav/advertisers')"></div>
        <div v-if="activeNav === 2" class="business-nav__active-underline"></div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

export default {
  name: 'BusinessNav',
  data() {
    return {
      activeNav: 1,
    };
  },
  methods: {
    changeNav(id) {
      this.activeNav = id;
      this.$emit('changenav', id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.business-nav {
  position: relative;
  background-color: #FFFFFF;
  color: $color-white;
  box-shadow: $elevation-deth-4;
  text-align: center;
  width: 100%;
  height: 64px;
  overflow: hidden;

  &__content {
    display: flex;
    justify-content: center;
  }

  &__item {
    position: relative;
    padding: 20px 16px;
    font-weight: 600;
    text-align: center;
    color: $color-black;
    cursor: pointer;
    &_active {
      color: $color-primary;
    }
  }

  &__active-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: var(--gradient-primary, linear-gradient(90deg, #FF8052 0%, #FF3300 100%));
  }

}
</style>
