<template>
  <div class="organizers">
    <div class="layout__main pt-0">
      <intro @sendRequest="showModal"></intro>
      <services></services>
      <cases></cases>
      <features></features>
      <how></how>
      <actions></actions>
    </div>

    <bitrix-form></bitrix-form>
  </div>
</template>

<script lang="js">
import { mapGetters } from "vuex";
import intro from './sections/intro.vue';
import services from './sections/services.vue';
import cases from './sections/cases.vue';
import features from './sections/features.vue';
import how from './sections/how.vue';
import actions from './sections/actions.vue';
import bitrixForm from './components/bitrix-form.vue';

export default {
  name: 'Organizers',
  components: {
    intro,
    services,
    cases,
    features,
    how,
    actions,
    bitrixForm,
  },
  data() {
    return {
      modal: null,
    }
  },
  computed: {
    ...mapGetters('SettingsState', ['lang']),
  },
  watch: {
    lang() {
      window.location.reload();
    },
  },
  created() {
    this.$root.$modals = {};
    this.$root.openBitrixForm = () => {
      const btn = document.querySelector('.bitrix-form');
      if (btn) {
        btn.click();
      }
    };
    if (!this.$root.useAnimations) {
      this.$root.useAnimations = window.innerWidth > 1199;
    }
  },
  mounted() {
    this.$store.state.showMenu = true
    this.$store.state.buttonCart = true;
    this.modal = this.$refs.requestModal;
  },
  methods: {
    showModal() {
      const modal = this.modal && this.modal.$refs.modal;
      if (!modal) return;
      modal.open();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './organizers.scss';
</style>
