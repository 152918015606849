<template>
  <section class="org-text section section_lg">
    <div class="container">
      <div class="section__group m-0">
        <div class="grid grid_v-margins-lg">
          <div class="grid__col">
            <div class="section__header">
              <h2 class="h1" v-html="$t('business/partners/header')"></h2>
            </div>
          </div>
        </div>
      </div>
      <div class="section__group">
        <div v-if="showAdvPartners" class="grid grid_v-margins org-text__logos">
          <div
            class="grid__col text-center org-text__logo"
            :class="isDesktop ? 'grid__col_xl-6' : (isTablet ? 'grid__col_12' : 'grid__col_xl-8')"
            v-for="(item, i) in advPartners"
            :key="i"
          >
            <img
              :src="'/img/' + item.img"
              alt=""
            >
          </div>
        </div>
        <div v-if="!showAdvPartners" class="grid grid_v-margins org-text__logos">
          <div
            class="grid__col text-center org-text__logo"
            :class="isDesktop ? 'grid__col_xl-6' : (isTablet ? 'grid__col_12' : 'grid__col_xl-8')"
            v-for="(item, i) in partners"
            :key="i"
          >
            <img
              :src="'/img/' + item.img"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

export default {
  name: 'Partners',
  props: {
    showAdvPartners: Boolean,
  },
  data() {
    return {
      advPartners: [
        {
          img: 'business/logos/pharmeco.svg',
        },
        {
          img: 'business/logos/nutrend.svg',
        },
        {
          img: 'business/logos/sportmaster.svg',
        },
        {
          img: 'business/logos/vsk.svg',
        },
      ],
      partners: [
        {
          img: 'business/logos/ironstar.svg',
        },
        {
          img: 'business/logos/heroes.svg',
        },
        {
          img: 'business/logos/timerman.svg',
        },
        {
          img: 'business/logos/happybook.svg',
        },
      ],
    };
  },
  computed: {
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isTablet() {
      return window.innerWidth >= 768 && window.innerWidth <= 1024;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.org-text {

  @media (min-width: breakpoint(lg)) {
    .h1 {
      text-align: center;
    }
  }

  &__logos {
    align-items: center;
  }

  &__logo {
    margin-bottom: 40px;

    @media (min-width: breakpoint(xl)) {
      margin-bottom: 0;
    }
  }
}

.org-feature {
  height: 100%;
}
</style>
