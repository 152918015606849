<template>
  <section
    id="how"
    class="section section_lg org-how"
    :style="'background-image: url(/img/photographers/how.jpg)'"
  >
    <div class="org-how__overlay-1"></div>
    <div class="container org-how__container">
      <div class="section__group m-0">
        <h2 class="h1 org-how__title" v-html="$t('photographers/how/title')"></h2>
        <div class="text-lg org-how__text" v-html="$t('photographers/how/text')"></div>
        <h3 class="h3 org-how__subtitle" v-html="$t('photographers/how/subtitle')"></h3>
      </div>
      <div class="section__group">
        <div class="org-steps">
          <div
            class="org-steps__item"
            v-for="(item, i) in steps"
            :key="i"
          >
            <div class="org-step">
              <div class="org-step__num"><span class="h2 m-0">{{ i + 1 }}</span></div>
              <div
                class="org-step__title"
                v-html="item.title"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'How',
  data() {
    return {
      steps: [
        {
          title: this.$t('photographers/how/title-1').toString(),
        },
        {
          title: this.$t('photographers/how/title-2').toString(),
        },
        {
          title: this.$t('photographers/how/title-3').toString(),
        },
        {
          title: this.$t('photographers/how/title-4').toString(),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.org-how {
  position: relative;
  background-size: cover;
  background-position: center;
  color: $color-white;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  @media (min-width: breakpoint(md)) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  &__overlay-1 {
    background: $color-black;
    opacity: 0.65;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__overlay-2 {
    background: $color-white;
    opacity: 0.45;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__container {
    position: relative;
    z-index: 1;
    opacity: 1;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 48px;
  }

  &__subtitle {
    margin-bottom: 24px;
  }
}

.org-steps {
  display: inline-block;
  margin: 0 -20px;
  text-align: center;
  max-width: 476px;
  margin: 16px 0;

  @media (min-width: breakpoint(lg)) {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }

  &__item {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    position: relative;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: breakpoint(lg)) {
      margin-bottom: 0;
    }
  }
}

.org-step {
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: left;

  @media (min-width: breakpoint(lg)) {
    display: block;
    text-align: center;
  }

  &__num {
    display: inline-block;
    vertical-align: top;
    border: 2px solid;
    border-radius: 50%;
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    line-height: 44px;
    margin-right: 24px;
    text-align: center;

    @media (min-width: breakpoint(md)) {
      width: 64px;
      height: 64px;
      line-height: 60px;
    }

    @media (min-width: breakpoint(lg)) {
      margin: 0 0 20px 0;
    }

    > span {
      line-height: inherit;
    }
  }

  &__title {
    @include text-lg;
  }
}
</style>
