<template>
  <section class="text-center">
    <div class="container">
      <div class="grid grid_gutters-sm grid_h-center grid_v-margins">
        <div class="grid__col grid__col_auto">
          <button
            v-if="$root.$i18n.locale === 'ru'"
            class="button button_lg button_primary"
            @click.prevent="$root.openBitrixForm()"
          >
            <span class="button__text" v-html="$t('organizers/intro/requestCalculation')"></span>
          </button>
          <a
            v-else
            class="button button_lg button_primary"
            href="mailto:info@mysport.photo"
          >
            <span class="button__text" v-html="$t('organizers/intro/requestCalculation')"></span>
          </a>
        </div>
        <div class="grid__col grid__col_auto org-actions-cta">
          <a
            class="button button_lg button_secondary"
            :href="`${$root.$i18n.locale === 'ru' ? 'presentation.pdf' : 'us-presentation.pdf'}`"
            target="_blank"
          >
            <span class="button__text" v-html="$t('organizers/action/presentation')"></span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'Actions',
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

section {
  margin-top: 48px;
  @include v-spacing("margin-bottom", xl);
  
  @media (min-width: breakpoint(md)) {
    margin-top: 64px;
  }
}

.button_secondary {
  background: none;
}

.org-actions-cta {
  @media (min-width: breakpoint(md)) {
    order: -1;
  }
}
</style>
