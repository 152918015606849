<template>
  <section
    class="org-intro mt-0"
  >
    <div
      class="org-intro__bg"
      :style="'background-image: url(/img/organizers/intro.jpg)'"
      ref="bg"
    ></div>
    <div class="org-intro__overlay overlay"></div>
    <div class="org-intro__content container">
      <div class="section__group mt-0">
        <div class="grid grid_h-center">
          <div class="grid__col grid__col_sm-20 grid__col_lg-16">
            <h1 class="section__header heading" v-html="$t('organizers/intro/title')"></h1>
            <h2 class="subtitle-1 color-white-85" v-html="$t('organizers/intro/subtitle')"></h2>
          </div>
        </div>
      </div>
      <div class="section__group">
        <div class="grid grid_gutters-sm grid_h-center grid_v-margins">
          <div class="grid__col grid__col_md-auto">
            <button
              v-if="$root.$i18n.locale === 'ru'"
              class="button button_lg button_primary"
              @click.prevent="$root.openBitrixForm()"
            >
              <span class="button__text" v-html="$t('organizers/intro/requestCalculation')"></span>
            </button>
            <a
              v-else
              class="button button_lg button_primary"
              href="mailto:info@mysport.photo"
            >
              <span class="button__text" v-html="$t('organizers/intro/requestCalculation')"></span>
            </a>
          </div>
          <div class="grid__col grid__col_md-auto org-intro__cta">
            <button
              class="button button_lg button_white"
              @click="scrollToId('how')"
            >
              <span class="button__text" v-html="$t('organizers/intro/howItWorks')"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { scrollToId } from '@/assets/js/animation';

export default {
  name: 'Intro',
  methods: {
    scrollToId,
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.org-intro {
  position: relative;
  color: $color-white;
  text-align: center;
  padding-bottom: 112px;
  margin-bottom: -112px;
  overflow: hidden;

  &__cta {

    @media (min-width: breakpoint(md)) {
      order: -1;
    }
  }

  &__bg {
    background-size: cover;
    background-position: top center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__overlay-color {
    background: linear-gradient(90deg, #82CA9C 0%, #00A651 100%);
    opacity: 0.45;
  }

  &__overlay {
    background-color: $color-black-65;
  }

  &__content {
    position: relative;
    padding-top: 64px;
    padding-bottom: 64px;

    @media (min-width: breakpoint(md)) {
      padding-top: 112px;
      padding-bottom: 112px;}
  }
}
</style>
