<template>
  <modal
    name="case-modal"
    align="center"
    ref="modal"
  >
    <div v-if="data">
      <div class="modal__heading modal__item">
        <div class="modal__top-img">
          <img :src="'/img/organizers/cases/' + data.logo" alt="">
        </div>
        <h3 class="modal__title" v-html="data.name"></h3>
        <div class="modal__subtitle" v-html="data.description"></div>
      </div>
      <div class="modal__item">
        <div class="msg msg_warning">
          <div class="msg__title text-lg"><b v-html="$t('organizers/caseModal/what')"></b></div>
          <ul class="list text-content mt-0">
            <li
              v-for="(item, i) in data.done"
              :key="i"
              v-html="item"
            ></li>
          </ul>
        </div>
        <div class="msg msg_success">
          <div class="msg__title text-lg"><b v-html="$t('organizers/caseModal/result')"></b></div>
          <ul class="list text-content mt-0">
            <li
              v-for="(item, i) in data.result"
              :key="i"
              v-html="item"
            ></li>
          </ul>
        </div>
      </div>
      <div class="modal__item">
        <button v-if="$root.$i18n.locale === 'ru'" class="button button_lg button_secondary" @click="openRequestModal">
          <span class="button__text" v-html="$t('organizers/caseModal/request')"></span>
        </button>
        <a v-else class="button button_lg button_secondary" href="mailto:info@mysport.photo">
          <span class="button__text" v-html="$t('organizers/caseModal/request')"></span>
        </a>
      </div>
    </div>
  </modal>
</template>

<script lang="js">
import Modal from "./modal";

export default {
  name: 'CaseModal',
  components: {
    Modal,
  },
  props: {
    data: Object,
  },
  methods: {
    openRequestModal() {
      this.$refs.modal.close();
      this.$root.openBitrixForm();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.modal__top-img img {
  max-height: 64px;
}

.msg {
  margin-top: 12px;
}
.msg .list {
  padding-left: 32px;
}
</style>
