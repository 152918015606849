<template>
  <section
    id="how"
    class="section section_lg org-how"
    :style="'background-image: url(/img/business/how2.jpg)'"
  >
    <div class="org-how__overlay-1"></div>
    <div class="container org-how__container">
      <div class="section__group m-0">
        <h1 class="h1 org-how__title" v-html="$t('business/how/header')"></h1>
        <div class="org-how__text" v-html="$t('business/how/subheader')"></div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'How',
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.org-how {
  position: relative;
  background-size: cover;
  background-position: center;
  color: $color-white;
  padding-top: 72px;
  padding-bottom: 64px;
  text-align: center;

  @media (min-width: breakpoint(md)) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  &__overlay-1 {
    background: $color-black;
    opacity: 0.75;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__overlay-2 {
    background: linear-gradient(90deg, #82CA9C 0%, #00A651 100%);
    opacity: 0.45;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__container {
    position: relative;
    z-index: 1;
    opacity: 1;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__text {
    @include text-md;
    @media (min-width: breakpoint(md)) {
      @include text-lg;
    }
  }

  &__subtitle {
    margin-bottom: 24px;
  }
}
</style>
