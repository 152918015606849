<template>
  <div class="business">
    <div class="layout__main pt-0">
      <intro @sendRequest="showModal"></intro>
      <business-nav @changenav="onNavChanged"></business-nav>
      <div v-if="activeNav === 1">
        <partner-services></partner-services>
        <statistic></statistic>
        <partner-features @sendRequest="showModal"></partner-features>
        <partners :showAdvPartners="false"></partners>
        <how></how>
        <actions @sendRequest="showModal"></actions>
      </div>
      <div v-if="activeNav === 2">
        <services></services>
        <statistic></statistic>
        <features @sendRequest="showModal"></features>
        <partners :showAdvPartners="true"></partners>
        <how></how>
        <actions @sendRequest="showModal"></actions>
      </div>
    </div>

    <request-modal ref="requestModal"></request-modal>
  </div>
</template>

<script lang="js">
import { mapGetters } from 'vuex';
import intro from './sections/intro.vue';
import businessNav from './sections/business-nav.vue';
import services from './sections/services.vue';
import statistic from './sections/statistic.vue';
import features from './sections/features.vue';
import partners from './sections/partners.vue';
import how from './sections/how.vue';
import actions from './sections/actions.vue';
import partnerServices from './sections/partner-services.vue';
import partnerFeatures from './sections/partner-features.vue';
import requestModal from './components/request-modal.vue';

export default {
  name: 'Business',
  components: {
    intro,
    businessNav,
    services,
    statistic,
    features,
    partners,
    how,
    actions,
    partnerServices,
    partnerFeatures,
    requestModal,
  },
  data() {
    return {
      modal: null,
      activeNav: 1,
    }
  },
  computed: {
    ...mapGetters('SettingsState', ['lang']),
  },
  watch: {
    lang() {
      window.location.reload();
    },
  },
  methods: {
    onNavChanged(navId) {
      this.activeNav = navId;
    },
    showModal() {
      const modal = this.modal && this.modal.$refs.modal;
      if (!modal) return;
      modal.open();
    }
  },
  created() {
    this.$root.$modals = {};
    this.$root.openBitrixForm = () => {
      const btn = document.querySelector('.bitrix-form');
      if (btn) {
        btn.click();
      }
    };
    if (!this.$root.useAnimations) {
      this.$root.useAnimations = window.innerWidth > 1199;
    }
  },
  mounted() {
    this.$store.state.showMenu = true
    this.$store.state.buttonCart = true;
    this.modal = this.$refs.requestModal;
  },
};
</script>

<style lang="scss" scoped>
  @import './business.scss';
</style>
