







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconSafetyFloat",
  props: {
    fill: {
      type: String,
      default: "white"
    },
  },
})
export default class IconSafetyFloat extends Vue {
}
