import axios from 'axios';

const instance = axios.create({
  baseURL: '/public/boring-service/api/v1',
  withCredentials: true,
});

export default {
  saveAnswers(answers) {
    return instance.post('/notifications', answers);
  },
};
