<template>
  <section>
    <div class="org-cases">
      <div class="org-cases__nav container">
        <div class="org-cases__prev">
          <button class="button button_lg button_circle button_shadow">
          <span class="button__icon">
            <icon name="arrow-left"></icon>
          </span>
          </button>
        </div>
        <div class="org-cases__next">
          <button class="button button_lg button_circle button_shadow">
          <span class="button__icon">
            <icon name="arrow-right"></icon>
          </span>
          </button>
        </div>
      </div>
      <div class="org-cases__container swiper-wrapper">
        <div
          class="org-cases__item swiper-slide"
          v-for="item in cases"
          :key="item.id"
        >
          <div class="org-case">
            <div class="container">
              <div
                class="org-case__img"
                :style="'background-image: url(/img/' + item.img + ')'"
              >
                <div class="org-case__logo">
                  <img :src="'/img/organizers/cases/' + item.logoLight" alt="">
                </div>
              </div>
              <div class="org-case__info">
                <div class="grid grid_h-center">
                  <div class="grid__col grid__col_lg-19 grid__col_xl-16">
                    <div class="block block_wide">
                      <div class="org-case__metrics">
                        <div
                          class="org-case__metrics-item"
                          v-for="(metric, i) in item.metrics"
                          :key="i"
                        >
                          <div class="heading" v-html="metric.val"></div>
                          <b class="text-sm" v-html="metric.text"></b>
                        </div>
                      </div>
                      <div class="org-case__footer">
                        <div class="org-case__footer-item org-case__footer-item_main">
                          <h3 v-html="item.name"></h3>
                        </div>
                        <div class="org-case__footer-item">
                          <button
                            :data-open-case="item.id"
                            class="link text-lg"
                          ><span v-html="$t('organizers/cases/view')"></span> →</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="org-cases__pagination"></div>
    </div>
    <case-modal ref="caseModal" :data="activeCase"></case-modal>
  </section>
</template>

<script lang="js">
import Swiper, { Pagination, Navigation, EffectFade } from 'swiper';
import ScrollMagic from 'scrollmagic';
import { TweenLite } from 'gsap';
import 'swiper/components/effect-fade/effect-fade.scss';
import caseModal from '../components/case-modal.vue';

Swiper.use([Pagination, Navigation, EffectFade]);

export default {
  name: 'Cases',
  components: {
    caseModal,
  },
  data() {
    return {
      slider: null,
      modal: null,
      activeCase: null,
      blocks: null,
      cases: [
        {
          id: 'ironstar-sochi',
          name: this.$t('organizers/cases/case-1/name').toString(),
          logoLight: 'IronStar_white.svg',
          logo: 'IronStar.svg',
          img: 'organizers/cases/Ironstar-Case.jpg',
          metrics: [
            { val: '52,7%', text: this.$t('organizers/cases/metric-1').toString() },
            { val: '995&#8239;₽', text: this.$t('organizers/cases/metric-2').toString() },
            { val: `2,5&#x202F;${this.$t('common/hours').toString()}`, text: this.$t('organizers/cases/metric-3', ['112']).toString() },
          ],
          description: this.$t('organizers/cases/case-1/description').toString(),
          done: this.$t('organizers/cases/case-1/done'),
          result: this.$t('organizers/cases/case-1/result'),
        },
        {
          id: 'velogonka-tour-de-france-l’etape-by-moscow',
          name: this.$t('organizers/cases/case-2/name').toString(),
          logoLight: 'HeroLeague_white.svg',
          logo: 'HeroLeague.svg',
          img: 'organizers/cases/HeroLeague-Case-1.jpg',
          metrics: [
            { val: '33,5%', text: this.$t('organizers/cases/metric-1').toString() },
            { val: '355&#8239;₽', text: this.$t('organizers/cases/metric-2').toString() },
            { val: `17&#x202F;${this.$t('common/hours').toString()}`, text: this.$t('organizers/cases/metric-3', ['15']).toString() },
          ],
          description: this.$t('organizers/cases/case-2/description').toString(),
          done: this.$t('organizers/cases/case-2/done'),
          result: this.$t('organizers/cases/case-2/result'),
        },
        {
          id: 'vserossiyskiy-polumarafon-zabeg.rf',
          name: this.$t('organizers/cases/case-3/name').toString(),
          logoLight: 'HeroLeague_white.svg',
          logo: 'HeroLeague.svg',
          img: 'organizers/cases/HeroLeague-Case-2.jpg',
          metrics: [
            { val: '21,1%', text: this.$t('organizers/cases/metric-1').toString() },
            { val: '325&#8239;₽', text: this.$t('organizers/cases/metric-2').toString() },
            { val: `12&#x202F;${this.$t('common/hours').toString()}`, text: this.$t('organizers/cases/metric-3', ['187']).toString() },
          ],
          description: this.$t('organizers/cases/case-3/description').toString(),
          done: this.$t('organizers/cases/case-3/done'),
          result: this.$t('organizers/cases/case-3/result'),
        },
        {
          id: 'polumarafon-moya-stolitsa',
          name: this.$t('organizers/cases/case-4/name').toString(),
          logoLight: 'Pharmeco_white.svg',
          logo: 'Pharmeco.svg',
          img: 'organizers/cases/Pharmeco-Case.jpg',
          metrics: [
            { val: '27,2%', text: this.$t('organizers/cases/metric-1').toString() },
            { val: '475&#8239;₽', text: this.$t('organizers/cases/metric-2').toString() },
            { val: `10&#x202F;${this.$t('common/hours').toString()}`, text: this.$t('organizers/cases/metric-3', ['77']).toString() },
          ],
          description: this.$t('organizers/cases/case-4/description').toString(),
          done: this.$t('organizers/cases/case-4/done'),
          result: this.$t('organizers/cases/case-4/result'),
        },
      ],
    };
  },
  methods: {
    openCase(caseId) {
      const modal = this.modal && this.modal.$refs.modal;

      if (!modal || !caseId) {
        return;
      }

      if (this.activeCase && this.activeCase.id === caseId) {
        modal.open();
        return;
      }

      let activeCase = null;
      for (let i = 0; i < this.cases.length; i += 1) {
        const caseItem = this.cases[i];
        if (caseItem.id === caseId) {
          activeCase = caseItem;
          break;
        }
      }
      this.activeCase = activeCase;

      if (activeCase) {
        modal.open();
      }
    },
    createSlider() {
      const self = this;

      this.slider = new Swiper('.org-cases', {
        navigation: {
          nextEl: '.org-cases__next',
          prevEl: '.org-cases__prev',
        },
        pagination: {
          el: '.org-cases__pagination',
          type: 'bullets',
          clickable: true,
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        speed: 750,
        simulateTouch: false,
        autoHeight: true,
        loop: true,
        on: {
          afterInit() {
            const buttons = this.$el[0].querySelectorAll('[data-open-case]');
            buttons.forEach((button) => {
              button.addEventListener('click', () => {
                self.openCase(button.getAttribute('data-open-case'));
              });
            });
            self.blocks = this.$el[0].querySelectorAll('.block');
            self.createScene();
          },
        },
      });

      if (this.$root.useAnimations) {
        this.slider.on('beforeTransitionStart', (swiper, speed, internal) => {
          if (!internal) {
            self.onSlideChange(swiper);
          }
        });
      }
    },
    createScene() {
      if (!this.$root.useAnimations) {
        return;
      }
      const controller = new ScrollMagic.Controller();
      new ScrollMagic.Scene({
        triggerElement: this.blocks[0],
        triggerHook: 1,
        reverse: false,
      })
        .on('start', () => {
          this.animateSlide(1);
        })
        .addTo(controller);
    },
    onSlideChange(swiper) {
      const { activeIndex, previousIndex } = swiper;
      const diff = activeIndex - previousIndex;
      this.animateSlide(activeIndex, diff);
    },
    animateSlide(index, diff) {
      const { slides } = this.slider;
      const block = slides[index].querySelector('.block');
      const ease = window.Power2.easeOut;
      const from = {
        y: 40,
        ease,
      };
      if (diff) {
        from.y = 0;
        from.x = 40 * diff;
      }
      TweenLite.set(this.blocks, {
        autoAlpha: 0,
      });
      TweenLite.set(block, {
        autoAlpha: 1,
      });
      TweenLite.fromTo(block, 0.9, from, {
        y: 0,
        x: 0,
        ease,
      });
    },
  },
  mounted() {
    this.modal = this.$refs.caseModal;
    this.createSlider();
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

section {
  margin-top: 48px;
}

.org-cases {
  position: relative;

  &__container {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  &__item {
    flex: 0 0 auto;
    width: 100%;
    pointer-events: none;

    &.swiper-slide-active {
      pointer-events: auto;
    }
  }

  &__nav {
    display: none;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 0;
    justify-content: space-between;
    top: 240px;

    @media (min-width: breakpoint(md)) {
      display: flex;
    }
  }

  &__next, &__prev {
    flex: 0 0 auto;
    margin-top: -24px;
  }

  &__prev {
    margin-left: -12px;
  }

  &__next {
    margin-right: -12px;
  }

  &__pagination {
    font-size: 0;
    line-height: 1;
    text-align: center;
    margin: 24px 0 16px;
    position: relative;

    ::v-deep > span {
      display: inline-block;
      vertical-align: top;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-black-25;
      margin: 0 4px;
      cursor: pointer;

      &.swiper-pagination-bullet-active {
        background-color: $color-primary;
      }
    }
  }
}

.org-case {
  position: relative;

  &__logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 8px 0;
    background-color: $color-black-65;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;

    @media (min-width: breakpoint(md)) {
      height: 80px;
      left: 0;
      transform: none;
      margin: 8px;
    }

    img {
      display: block;
      padding: 16px;
      height: 100%;
    }
  }

  &__img {
    width: 100%;
    height: 256px;
    background-size: cover;
    background-position: center;
    background-color: $color-black-15;
    border-radius: 4px;
    position: relative;
    margin-bottom: -40px;

    @media (min-width: breakpoint(md)) {
      height: 400px;
      margin-bottom: -80px;
    }

    @media (min-width: breakpoint(lg)) {
      height: 480px;
      margin-bottom: -160px;
    }
  }

  &__info {
    position: relative;
    padding: 0 24px;

    @media (min-width: breakpoint(md)) {
      padding: 0 48px;
    }

    @media (min-width: breakpoint(lg)) {
      padding: 0;
    }
  }

  &__metrics {
    display: flex;
    flex-direction: column;
    margin: 0 -24px -24px;

    @media (min-width: breakpoint(md)) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__metrics-item {
    flex: 1 1 0;
    max-width: 100%;
    text-align: center;
    padding: 0 24px;
    margin-bottom: 24px;
  }

  &__footer {
    text-align: center;
    margin: 48px -24px -24px;

    @media (min-width: breakpoint(lg)) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      text-align: left;
    }
  }

  &__footer-item {
    flex: 0 0 auto;
    max-width: 100%;
    padding: 0 24px;
    margin-bottom: 24px;

    &_main {
      flex-shrink: 1;
      min-width: 0;
    }
  }
}
</style>
