<template>
  <button class="bitrix-form" style="display: none;"></button>
</template>

<script lang="js">
export default {
  name: 'BitrixForm',
  mounted() {
    const script = document.createElement('script');
    script.setAttribute('data-b24-form', 'click/18/9dcxeb');
    script.setAttribute('data-skip-moving', 'true');
    script.innerHTML = `(function (w, d, u) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + (Date.now() / 180000 | 0);
      var h = d.getElementsByTagName('script')[0];
      h.parentNode.insertBefore(s, h);
    })(window, document, 'https://cdn-ru.bitrix24.ru/b2752387/crm/form/loader_18.js');`;
    this.$el.parentNode.insertBefore(script, this.$el);
  },
};
</script>
