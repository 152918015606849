<template>
  <section>
    <div class="org-cases">
      <div class="org-cases__nav container">
        <div class="org-cases__prev">
          <button class="button button_lg button_circle button_shadow">
          <span class="button__icon">
            <icon name="arrow-left"></icon>
          </span>
          </button>
        </div>
        <div class="org-cases__next">
          <button class="button button_lg button_circle button_shadow">
          <span class="button__icon">
            <icon name="arrow-right"></icon>
          </span>
          </button>
        </div>
      </div>
      <div class="org-cases__container swiper-wrapper">
        <div
          class="org-cases__item swiper-slide"
          v-for="item in cases"
          :key="item.id"
        >
          <div class="org-case">
            <div class="container">
              <div
                class="org-case__img"
                :style="'background-image: url(' + '/img/' + item.img + ')'"
              >
              </div>
              <div class="org-case__info">
                <div class="grid grid_h-center">
                  <div class="grid__col grid__col_lg-19 grid__col_xl-16">
                    <div class="block block_wide">
                      <div class="org-case__avatar">
                        <img :src="'/img/' + item.avatar" alt="">
                      </div>
                      <div class="org-case__text">
                        <div class="org-case__text-name text-lg b">
                          <span v-html="item.name"></span>
                        </div>
                        <div class="org-case__text-title text-xs">
                          <span v-html="item.title"></span>
                        </div>
                        <div class="org-case__text-review text-md">
                          <p
                            v-for="(p, i) in item.review"
                            :key="i"
                            v-html="p"
                          >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="org-cases__pagination"></div>
    </div>
  </section>
</template>

<script>
import Swiper, { Pagination, Navigation, EffectFade } from 'swiper';
import ScrollMagic from 'scrollmagic';
import { TweenLite } from 'gsap';
import 'swiper/components/effect-fade/effect-fade.scss';

Swiper.use([Pagination, Navigation, EffectFade]);

export default {
  name: 'Cases',
  data() {
    return {
      slider: null,
      activeCase: null,
      blocks: null,
      cases: [
        {
          id: 'case-1',
          img: 'photographers/collage.jpg',
          avatar: 'photographers/cases/avatar-1.jpg',
          name: this.$t('photographers/cases/name-1').toString(),
          title: this.$t('photographers/cases/title-1').toString(),
          review: this.$t('photographers/cases/review-1'),
        },
        {
          id: 'case-2',
          img: 'photographers/collage.jpg',
          avatar: 'photographers/cases/avatar-2.jpg',
          name: this.$t('photographers/cases/name-2').toString(),
          title: this.$t('photographers/cases/title-2').toString(),
          review: this.$t('photographers/cases/review-2'),
        },
      ],
    };
  },
  methods: {
    createSlider() {
      const self = this;

      this.slider = new Swiper('.org-cases', {
        navigation: {
          nextEl: '.org-cases__next',
          prevEl: '.org-cases__prev',
        },
        pagination: {
          el: '.org-cases__pagination',
          type: 'bullets',
          clickable: true,
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        speed: 750,
        simulateTouch: false,
        autoHeight: true,
        loop: true,
        on: {
          afterInit() {
            self.blocks = this.$el[0].querySelectorAll('.block');
            self.createScene();
          },
        },
      });

      if (this.$root.useAnimations) {
        this.slider.on('beforeTransitionStart', (swiper, speed, internal) => {
          if (!internal) {
            self.onSlideChange(swiper);
          }
        });
      }
    },
    createScene() {
      if (!this.$root.useAnimations) {
        return;
      }
      const controller = new ScrollMagic.Controller();
      new ScrollMagic.Scene({
        triggerElement: this.blocks[0],
        triggerHook: 1,
        reverse: false,
      })
        .on('start', () => {
          this.animateSlide(1);
        })
        .addTo(controller);
    },
    onSlideChange(swiper) {
      const { activeIndex, previousIndex } = swiper;
      const diff = activeIndex - previousIndex;
      this.animateSlide(activeIndex, diff);
    },
    animateSlide(index, diff) {
      const { slides } = this.slider;
      const block = slides[index].querySelector('.block');
      const ease = window.Power2.easeOut;
      const from = {
        y: 40,
        ease,
      };
      if (diff) {
        from.y = 0;
        from.x = 40 * diff;
      }
      TweenLite.set(this.blocks, {
        autoAlpha: 0,
      });
      TweenLite.set(block, {
        autoAlpha: 1,
      });
      TweenLite.fromTo(block, 0.9, from, {
        y: 0,
        x: 0,
        ease,
      });
    },
  },
  mounted() {
    this.createSlider();
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

section {
  margin-top: 48px;
}

.org-cases {
  position: relative;

  &__container {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  &__item {
    flex: 0 0 auto;
    width: 100%;
    pointer-events: none;

    &.swiper-slide-active {
      pointer-events: auto;
    }
  }

  &__nav {
    display: none;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 0;
    justify-content: space-between;
    top: 240px;

    @media (min-width: breakpoint(md)) {
      display: flex;
    }
  }

  &__next, &__prev {
    flex: 0 0 auto;
    margin-top: -24px;
    .button__icon {
      color: $color-black-85;
    }
  }

  &__prev {
    margin-left: -12px;
  }

  &__next {
    margin-right: -12px;
  }

  &__pagination {
    font-size: 0;
    line-height: 1;
    text-align: center;
    margin: 24px 0 16px;
    position: relative;

    ::v-deep > span {
      display: inline-block;
      vertical-align: top;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-black-25;
      margin: 0 4px;
      cursor: pointer;

      &.swiper-pagination-bullet-active {
        background-color: $color-primary;
      }
    }
  }
}

.org-case {
  position: relative;

  &__img {
    width: 100%;
    height: 256px;
    background-size: cover;
    background-position: center;
    background-color: $color-black-15;
    border-radius: 4px;
    position: relative;
    margin-bottom: -40px;

    @media (min-width: breakpoint(md)) {
      height: 400px;
      margin-bottom: -80px;
    }

    @media (min-width: breakpoint(lg)) {
      height: 480px;
      margin-bottom: -160px;
    }
  }

  &__info {
    position: relative;
    padding: 0 24px;

    @media (min-width: breakpoint(md)) {
      padding: 0 48px;
    }

    @media (min-width: breakpoint(lg)) {
      padding: 0;
    }

    .block {
      padding: 24px;
      @media (min-width: breakpoint(md)) {
        display: flex;
        padding: 32px 40px;
      }
    }
  }

  &__avatar {
    width: 100%;
    text-align: center;
    @media (min-width: breakpoint(md)) {
      width: 64px;
      margin-right: 16px;
    }

    img {
      width: 64px;
      height: 64px;
      border-radius: 999px;
    }
  }

  &__text {
    width: 100%;
    @media (min-width: breakpoint(md)) {
      width: calc(100% - 16px - 64px);
    }
  }

  &__text-name {
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: $color-black;
    margin-bottom: 4px;
    @media (min-width: breakpoint(md)) {
      text-align: left;
    }
  }

  &__text-title {
    width: 100%;
    text-align: center;
    color: $color-black;
    margin-bottom: 4px;
    @media (min-width: breakpoint(md)) {
      text-align: left;
    }
  }

  &__text-review {
    width: 100%;
    color: $color-black-85;
  }

}

p {
  margin: 0;
  padding: 0;
  vertical-align: baseline;  
}
</style>
