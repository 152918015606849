<template>
  <section class="org-services section section_lg mt-0">
    <div class="container">
      <div class="section__group m-0">
        <div class="grid grid_v-margins">
          <div
            class="grid__col " :class="isTablet ? 'grid__col_md-12' : 'grid__col_md-6'"
            v-for="(item, i) in services"
            :key="i"
          >
            <div ref="blocks" class="block block_wide text-center">
              <div class="block__intro">
                <div class="block__intro-item block__intro-item_md" v-if="item.img">
                  <img
                    :src="'/img/' + item.img"
                    width="234"
                    height="174"
                    alt=""
                  >
                </div>
                <div class="block__intro-item">
                  <h3 class="h3" v-html="item.title"></h3>
                </div>
                <div
                  class="block__intro-item text-md color-black-85"
                  v-html="item.text"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ScrollMagic from 'scrollmagic';
import { TweenLite, TimelineLite } from 'gsap';

export default {
  name: 'Services',
  data() {
    return {
      services: [
        {
          title: this.$t('photographers/services/title-1').toString(),
          text: this.$t('photographers/services/text-1').toString(),
          img: 'photographers/focus-rafiki-1.svg',
        },
        {
          title: this.$t('photographers/services/title-2').toString(),
          text: this.$t('photographers/services/text-2').toString(),
          img: 'photographers/learning-rafiki.svg',
        },
        {
          title: this.$t('photographers/services/title-3').toString(),
          text: this.$t('photographers/services/text-3').toString(),
          img: 'photographers/investment-data-rafiki.svg',
        },
        {
          title: this.$t('photographers/services/title-4').toString(),
          text: this.$t('photographers/services/text-4').toString(),
          img: 'photographers/high-five-rafiki.svg',
        },
      ],
    };
  },
  methods: {
    initAnimation() {
      const { blocks } = this.$refs;
      const controller = new ScrollMagic.Controller();
      const ease = window.Power2.easeOut;

      blocks.forEach((block) => {
        const tl = new TimelineLite();

        TweenLite.set(block, {
          y: 40,
        });

        tl
          .to(block, 0.9, {
            y: 0,
            ease,
          });

        new ScrollMagic.Scene({
          triggerElement: block,
          triggerHook: 1,
          reverse: false,
        })
          .setTween(tl)
          .addTo(controller);
      });
    },
  },
  computed: {
    isTablet() {
      return window.innerWidth >= 768 && window.innerWidth <= 1024;
    },
  },
  mounted() {
    if (this.$root.useAnimations) {
      this.initAnimation();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";
@import "@/scss/static-pages/main.scss";

.org-services {
  position: relative;
}

.block {
  height: 100%;
  padding: 32px 24px;

  &__intro {
    flex-direction: column;
    @media (min-width: breakpoint(lg)) {
      display: flex;
    }
  }

  @media (min-width: breakpoint(md)) {
    padding: 32px 40px;
  }

}

.block {
  height: 100%;
  padding: 32px 40px;
  @media (min-width: breakpoint(xl)) {
    &__intro-item {
      text-align: left !important;
    }
  }
}
</style>
